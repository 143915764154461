.menu-title {
  position: fixed;
  top: 2.5%;  
  left: 5%;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.menu-hidden{
  opacity: 0;
  transform: translateY(50px);
}

.navbar-wrapper {
  position: fixed;
  top: 5%;  
  left: 3%;
  height: 280px;
  width: 100px;
  z-index: 1000;
  overflow: visible; 
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  max-height: 100px;
}
.navbar-wrapper-home{
  position: absolute;
}

.navbar {
  position: fixed;
  top: 5%;
  left: 3%;
  height: 327px;
  width: 100px;
  background-color: black;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 1);
  z-index: 1000;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;
  max-height: 100px;
  max-width: 100px;
}

.navbar.open {
  padding-top: 20px;
  top: 5%;
  left: 5%;
  width: 200px;
  max-height: 400px;
  max-width: 400px;
}

.navbar-home{
  position: absolute;
  top: 110px;
  left: 0;
  margin-left: 6vw;
  height: 500px;
  width: 500px;
  max-height: 500px;
  max-width: 500px;
  transition: all 0.3s ease-in-out;
}

.logo-home{
  max-width: 500px !important;
  max-height: 500px !important;
  transition: all 0.3s ease-in-out;
}

.navbar-hidden{
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-unhidden{
  transition: all 0.3s ease-in-out;
}

.navbar-wrapper::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 1); 
  transform: translate(-50%, -50%) scale(0.1);
  opacity: 0;
  z-index: -1;
  animation: ripple 2.5s infinite ease-out;
  pointer-events: none;
}

.navbar-wrapper.open::before {
  display: none;
}

.navbar-wrapper.navbar-wrapper-home::before{
  display: none;
}

@keyframes ripple {
  0%{
    opacity: 0;
  }
  19.5%{
    opacity: 0;
  }
  20% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 1;
  }
  70% {
    transform: translate(-50%, -50%) scale(1.3);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}



  .logo-container {
    display: flex;
    justify-content: center;
    padding: 2%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .logo {
    width: 500px;
    height: 500px;
    max-width: 100px;
    max-height: 100px;
    transition: all 0.3s ease-in-out;
    cursor:pointer;
  }
  
  .logo:hover {
    transform: scale(1.1);
  }
  
  .nav-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .nav-item {
    padding: 15px 20px;
    text-decoration: none;
    color: #fff;
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
    border-radius: 4px;
  }
  
  .nav-item:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
    transform: scale(1.2);
  }
  
  .nav-item.active {
    background-color: rgba(255, 255, 255, 0.6);
    color: #000000;
    font-weight: bold;
  }
  
  .area-utente {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .area-utente:hover {
    background-color: rgba(0, 123, 255, 0.6);
    transform: translateX(10px);
  }
  
  .user-dropdown {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .area-utente.open + .user-dropdown {
    opacity: 1;
    max-height: 100px;
  }
  
  .dropdown-item {
    padding: 10px;
    text-decoration: none;
    color: #fff;
    display: block;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .dropdown-item.active {
    background-color: rgba(0, 123, 255, 0.9);
    font-weight: bold;
  }
  
  .dropdown-item:hover {
    background-color: rgba(0, 123, 255, 0.5);
    transform: translateX(15px);
  }
  
  @media (max-width: 768px) {
    .navbar {
      top: -2%;
      left:-2%;
    }
    .navbar.open{
      top: 2%;
    }
    .navbar-wrapper{
      top: -2%;
      left:-2%;
    }
    .navbar-home{
      top: 200px;
      left: -30px;
      height: 360px;
      width: 360px;
    }

    .logo-home{
      max-height: 360px !important;
      max-width: 360px !important;
    }

    .menu-title{
      opacity: 0;
      z-index: 0;
    }

  
  }

    @media (max-width: 480px) {
      .navbar-home{
        left: -100px;
        top:180px;
        max-width: 300px;
        max-height: 300px;

      }
      .logo-home{
        max-width: 300px !important;
        max-height: 300px !important;
      }
      

    
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    
    .navbar-home{
        max-width: 320px;
        max-height: 320px;
        left: 0vw !important;
        margin-top: 0px;
        transform: translateX(-30%);
    }
    .logo-home{
      max-width: 320px !important;
      max-height: 320px !important;
    }
  }
  