.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    perspective: 25vw;
    perspective-origin: 50% 10%;   
    background: linear-gradient(to bottom, #212121, #000000);
}

.carousel {
    display: flex;
    flex-direction: row;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .carousel{
    visibility: hidden;
  }
  .carousel-container{
    opacity: 0;
    height: 20vh;
  }
  .character-details{
    margin-top: -20vh !important;
  }
}

.character {
    width: 20%;
    height: 20%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    position: absolute;
    transform-origin: center center 0px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.icons {
    position: absolute;
    width: 17vh;
}

.character-details {
    margin-top: 25vh;
    text-align: center;
    
  }

  .character-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .character-details p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }

  .arrow {
    position: relative;
    width: 20%;
    height: auto; 
    padding-top: 20%; 
    top: 50%;
    border-left: none;
    border-top: none;
    border-right: 4px #fff solid;
    border-bottom: 4px #fff solid;
    transition: transform 0.2s; 
  
    &.left {
      top: 10vh;
      -webkit-transform: rotate(137deg);
      left: 10%; 
    }
  
    &.right {
      top: 10vh;
      -webkit-transform: rotate(-45deg); 
      right: -70% !important;
    }
  
    &.right:hover {
      -webkit-transform: rotate(-45deg) scale(1.2); 
      
    }

    &.left:hover {
      -webkit-transform: rotate(137deg) scale(1.2);
      
    }

    &.bottom:hover {
      -webkit-transform: rotate(-135deg) scale(1.2);

    }

    &.bottom {
      -webkit-transform: rotate(-135deg);

    }
  }
  
  .arrow:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 50%;
    margin: -25% 0 0 -25%;
    border-left: none;
    border-top: none;
    border-right: 1px #fff solid;
    border-bottom: 1px #fff solid;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  
    &.left {
      -webkit-transform: rotate(137deg); 
    }
  
    &.right {
      -webkit-transform: rotate(-45deg); 
    }
    &.bottom {
      -webkit-transform: rotate(90deg); 

    }
  }
  
  @keyframes arrow {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-10px, -10px);
    }
  }
  
  .arrowLeft {
    position: fixed;
    width: 20%; 
    height: auto; 
    display: block; 
    left: 5%;
    transition: opacity 0.3s ease-in-out;
  }
  
  .arrowRight {
    position: fixed;
    width: 20%; 
    height: auto; 
    display: block;
    right: 5% !important;
    transition: opacity 0.3s ease-in-out;
  }
  
  .arrowBottom {
    position: fixed;
    width: 20%;
    height: auto;
    display: block;
    bottom: 5% !important;
    left: 15% !important;
    transition: opacity 0.3s ease-in-out;
  }

  .arrow-hidden {
    opacity: 0;
  }