.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Autobus'; 
  src: url('./img/Autobus.ttf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  font-family: 'Autobus', sans-serif;
  margin: 0;
  background-color: black;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1) 75%),
                    linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1) 75%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.route-section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: transform 300ms ease-in-out;
}

.fade-enter {
  transform: translateX(-100%);
}

.fade-enter-active {
  transform: translateX(0%);
}

.fade-exit {
  transform: translateX(0%);
}

.fade-exit-active {
  transform: translateX(100%);
}





