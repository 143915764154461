.language-chessboard {
    min-height: 100vh;
    transition: background-color 0.3s, color 0.3s;
    background-color: #000000;
    color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .theme-toggle {
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    background-color: #333333;
    color: #ffffff;
  }
  
  .language-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .language-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .language-item {
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06);
    overflow: hidden;
  }
  
  .language-item.even { background-color: #1a1a1a; }
  .language-item.odd { background-color: #262626; }
  
  .language-content {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  
  .language-info {
    flex-grow: 1;
    padding-right: 1rem;
  }
  
  .language-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #cccccc;
  }
  
  .language-description {
    font-size: 0.875rem;
    color: #999999;
  }
  
  .language-icon {
    flex-shrink: 0;
    color: #cccccc;
  }

  .skills-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #cccccc;
  }