
.homepage {
    padding: 50px;
    
  }
  
  .hero-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 100px 20px;
    margin-left: 10vw;
    margin-right: 10vw;
    min-height: 600px;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    background: linear-gradient(135deg, #000000 0%, #000000 20%, #444444 100%);
    color: white;
    text-align: center;
  }
  
  .hero-content-left {
    padding: 20px;
  }
  
  .hero-content-right {
    padding: 20px;
    text-align: center;
  }
  
  .hero-content-right img {
    max-width: 100%;
    height: auto;
    border-radius: 5%;
  }
  
  .hero-title {
    font-size: 4rem;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    color: #cccccc;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }

  @media (max-width: 768px) {
    .hero-section {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .hero-content-left, .hero-content-right {
      padding: 10px;
    }

    .hero-title{
      font-size: 3rem; 
      position: absolute;
      right:20%;
      top: 20px;
    }
  
    .hero-subtitle {
      font-size: 1.5rem;
    }
  
    .hero-content-right {
      margin-top: 200px; 
    }
  }

  @media (min-width: 768px) and (max-width: 1400px) {

    .hero-title {
      position: absolute;
      font-size: 2.5rem;
      left: 50%; 
      top: 30px;
      transform: translateX(-50%); 
    }

    .hero-section{
      margin-bottom: -220px;
      border-bottom-right-radius: 40%;
      border-bottom-left-radius: 40%;
    }

    .hero-subtitle{
      font-size: 1.2rem;
      position: relative;
      margin-bottom: 0px;
      margin-top: -180px;
    }

    .button-container {
      margin-top: 200px;
      margin-left: 10vw !important;
      height: 0px !important;
      transform: translateX(-15%);
    }

    .btn:link, .btn:visited{
      padding: 20px 40px !important;
      margin-left: 30px;
      top: -70px;
    }

    .text-box.one{
      margin-left: -20px !important;
    }
    .icon-wrapper{
      padding: 5px !important;
    }
    .text-box.three{
      margin-top: -0px !important;
      margin-left: -50px !important;
    }
    
  
  }

  @media (max-width: 480px) {
    .button-container{
      margin-left: 100px !important;
    }

    .hero-subtitle{
      font-size: 1.2rem;
    }

    .hero-section{
      margin-left: auto;
      margin-right: auto;
    }

    .btn:link, .btn:visited{
      padding: 20px 40px !important;
      margin-left: 30px;
      top: -70px;
    }

    .text-box.one{
      margin-left: -20px !important;
    }
    .icon-wrapper{
      padding: 5px !important;
    }
    .text-box.three{
      margin-top: -30px !important;
    }
    .hero-subtitle{
      top: 200px !important;
    }
    .hero-title{
      margin-left: 20%;
      font-size: 2.5rem;
    }
    .hero-section{
      margin-bottom: -130px;
    }
    .text-box-hide{
      animation: goodbyeTwo 0.3s ease-out forwards !important;
    }
  }
  

  
  .cta-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: white;
    color: #0072ff;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }

  .button-container {
    position: absolute;
    display: flex;
    flex-direction: column;  
    align-items: flex-start;
    justify-content: center; 
    margin-left: 300px;
    top: 200px;
    height: 600px;
    padding: 0 5vw;
}

.text-box {
  font-size: 1.2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.text-box.one {
    margin-left: -30px;
}

.text-box.three{
  margin-left: -130px;
  margin-top: -20px;
}

.icon-wrapper {
    background-color: black;
    top: 20vh;
    border-radius: 50%;
    padding: 40px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-wrapper img {
    width: 60px;
    height: 60px;
}

.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: relative;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(98, 98, 98, 0.836);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: #000000;
    box-shadow: 0 0 5px rgba(255, 255, 255, 1);
    color: #ffffff;
    top: -30px;
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
}

.btn-white::after {
    background-color: #474747;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.text-box:hover,
.text-box:active {
    transform: translateY(-5px);
}

.text-box:hover, .text-box:hover .icon-wrapper {
    transform: translateY(-5px);
}

.text-box:active, .text-box:active .icon-wrapper {
    transform: translateY(-2px);
}

@media (max-width: 768px) {
.button-container{
  margin-left: 0;
  height: 10px;
  margin-top: 240px;
  margin-left: 200px;
}

.btn:link, .btn:visited{
  padding: auto;
}

.icon-wrapper{
  padding: 20px;
}

.two{
  margin-left: -30px;
}

.hero-title{
  position: absolute;
  right:20%;
  top: 20px;
}

.hero-subtitle{
  position: relative;
  top: 270px;
  margin-bottom: 250px;
}

}

@keyframes slideInOne {
  0% {
    opacity: 0;
    transform: translateX(-150%) translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-one {
  animation: slideInOne 0.3s ease-out forwards;
}



@keyframes slideInTwo {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-two {
  animation: slideInTwo 0.5s ease-out forwards;
}



@keyframes slideInThree {
  0% {
    opacity: 0;
    transform: translateX(-150%) translateY(-70%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-three {
  animation: slideInThree 0.7s ease-out forwards;
}

@keyframes goodbye {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-150%) translateY(150%);
  }
}

@keyframes goodbyeTwo {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%) translateY(70%);
  }
}

.text-box-hide{
  animation: goodbye 0.2s ease-out forwards;
}