.footer {
  background-color: #000; 
  color: #ccc;
  padding: 40px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  margin: 0 20px;
}

.footer-section h2 {
  font-size: 20px;
  color: #fff; 
  margin-bottom: 20px;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.6;
  color: #aaa;
}

.footer-section .social-icons {
  display: flex;
  gap: 10px;

}

.social-icons{
  margin-left: 6vw;
}

.footer-section .social-icons i {
  font-size: 24px;
  color: #ccc;
}

.footer-section .social-icons i:hover {
  color: #fff; 
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #333; 
  color: #777;
}

.footer-bottom p {
  margin: 0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-bottom: 20px;
  }
}
