
.project-container {
    color: white;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .project-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .project-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}
  
  .project-left-column {
    width: 28%;
    background-color: #8484847d; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-shadow: 
        1px 1px 0 black;
    color: white; 
    align-self: flex-start; 
}

.project-left-column h1,
.project-left-column p { 
    color: white; 
}
  

  .project-details {
    list-style-type: none;
    padding: 0;
  }
  
  .project-details li {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
  
  .project-details strong{
    font-size: 1.6rem;
    text-shadow: 
    0px 0px 2px rgb(255, 255, 255);
  }
  
  .project-details a {
    color: #007bff;
    text-decoration: none;
  }
  
  .project-details a:hover {
    text-decoration: underline;
  }

  .project-right-column {
    width: 65%;
    padding: 20px;
    background-color: #ffffff15;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  }
  
  .project-right-column p {
    color: rgba(255, 255, 255, 0.815);
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .project-right-column li {
    color: rgba(255, 255, 255, 0.815);
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 20px;

  }
  .centered-text{
    text-align: left;
  }

  .project-right-column strong {
    font-size: 1.6rem;
    text-shadow: 
    0px 0px 2px rgb(255, 255, 255);
  }

  .project-icon {
    width: 200px;
    height: auto;
    display: block; 
    margin: 0 auto 20px; 
}

.project-images {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
  margin-top: 20px; 
}

.project-image {
  width: 48%; 
  height: auto;
  border-radius: 8px;
}

.project-image-large{
  padding: 20px;
  width: 78%;
  height: auto; 
  border-radius: 20px;
}


  @media (max-width: 768px) {
    .project-content {
      flex-direction: column;
      align-items: center;
    }
  
    .project-left-column, .project-right-column {
      width: 100%;
      margin-bottom: 20px;
    }

    .project-left-column{
      align-self: auto;
    }

  .project-images {
      flex-direction: column; 
      align-items: center; 
  }

  .project-image {
      width: 90%; 
      margin-bottom: 20px; 
  }
  .project-right-column p, .project-right-column li{
    font-size: 1rem;
  }
  .project-right-column strong{
    font-size: 1.2rem;
  }
  
  .project-title {
    margin-top: 60px;
    font-size: 2.5rem;
  }

  }
  