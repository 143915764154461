.curriculum-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background: linear-gradient(to bottom, #212121, #000000);
  }

  .curriculum-title {
    color: white;
    margin-bottom: 20px;
    font-size: 36px; 
    text-align: center; 
  }
  
  .curriculum-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-width: 400px;
    transition: filter 0.3s ease;
  }
  
  
  .curriculum-image-container.hovered .curriculum-preview-image {
    filter: blur(3px) brightness(0.7);
  }
  

  .curriculum-overlay:hover .download-icon {
    transform: scale(1.2);
  }
  
  .curriculum-preview-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    transition: filter 0.3s ease;
  }
  
  .curriculum-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor:pointer;
    transition: filter 0.3s ease, transform 0.3s ease;
  
  }
  
  .curriculum-overlay a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto; 
    transition: filter 0.3s ease, transform 0.3s ease;
  
  }
  
  .download-icon {
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .download-icon:hover {
    transform: scale(1.2); 
  }
  
  @media (max-width: 768px) {
    .curriculum-container{
        height: 100vh;
    }
    .curriculum-image-container {
      width: 80%; 
      padding: 10px;
    }
  }
  
  @media (min-width: 768px) {
    .curriculum-image-container {
      width: 40%;
    }
  }
  