.contattami-container {
    background-color: #000;
    color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .contattami-container h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  
  .contact-form {
    width: 100%;
    max-width: 500px;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .input-group input,
  .input-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .input-group textarea {
    height: 100px;
    resize: vertical;
  }
  
  .input-group label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    color: #999;
  }
  
  .input-group input:focus,
  .input-group textarea:focus,
  .input-group input:not(:placeholder-shown),
  .input-group textarea:not(:placeholder-shown) {
    border-bottom-color: #00ff00;
  }
  
  .input-group input:focus + label,
  .input-group textarea:focus + label,
  .input-group input:not(:placeholder-shown) + label,
  .input-group textarea:not(:placeholder-shown) + label {
    transform: translateY(-20px);
    font-size: 0.8rem;
    color: #00ff00;
  }
  
  .submit-btn {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  
  .submit-btn:hover {
    background-color: #717171;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgb(255, 255, 255);
  }

  .contact-form button{
    background-color: #fff;
    color: black;
  }
  
  @media (max-width: 768px) {
    .contattami-container h1 {
      font-size: 2rem;
    }
    
    .contact-form {
      max-width: 100%;
    }
    
  }